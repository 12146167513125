<template>
  <div class="ExamScanning">
    <detailsHeader
      ref="detailsHeader"
      router-url="/exam/unionExam"
      :query="{
        examId: $route.query.examId,
      }"
    >
    </detailsHeader>
    <div class="tab-box">
      <div class="btn-box">
        <span>扫描点列表</span>
        <div class="btn-list-box">
          <el-input
            v-model="keyword"
            placeholder="关键词搜索"
            @keyup.enter.native="handleClick"
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="handleClick"
            >
            </i>
          </el-input>
          <el-button
            v-if="status == 1"
            type="primary"
            @click="showAddStudent()"
          >
            <img src="@/assets/newExam/student/icon-add.svg" alt="" />
            <img
              class="on"
              src="@/assets/newExam/student/icon-add.svg"
              alt=""
            />
            添加
          </el-button>
          <el-button type="primary" @click="importFile()">
            <img src="@/assets/newExam/student/icon-import.svg" alt="" />
            <img
              class="on"
              src="@/assets/newExam/student/icon-import.svg"
              alt=""
            />
            导入
          </el-button>
          <el-button type="primary" @click="exportList()">
            <img src="@/assets/newExam/student/icon-export.svg" alt="" />
            <img
              class="on"
              src="@/assets/newExam/student/icon-export.svg"
              alt=""
            />
            导出
          </el-button>
          <el-button v-if="status == 1" type="primary" @click="showClear()">
            <img src="@/assets/uExam/icon-del.png" alt="" />
            <img class="on" src="@/assets/uExam/icon-del.png" alt="" />
            批量删除
          </el-button>
        </div>
      </div>
      <el-table
        v-if="examInformation.id"
        ref="multipleTable"
        border
        :data="dataList"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <!-- <el-table-column
          label="学段"
          width="180"
          prop="studentNo"
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          label="扫描点名称"
          width="180"
          prop="name"
          align="center"
        >
        </el-table-column>
        <el-table-column label="扫描点学校" prop="schoolNames" align="center">
          <template slot-scope="{ row }">
            <div class="name-box">
              <div v-for="(item, index) in row.schoolNames" :key="index">
                {{ item }}
              </div>
            </div></template
          >
        </el-table-column>
        <el-table-column
          label="管理人员"
          width="565"
          prop="studentNo"
          align="center"
        >
          <template slot-scope="{ row }">
            <div class="user-box">
              <div v-if="row.userInfoVOList" class="user-list">
                <div
                  v-for="(item, index) in row.userInfoVOList"
                  :key="index"
                  class="user-list-box"
                >
                  <div class="name">{{ item.name }}</div>
                  <div>{{ item.username }}</div>
                </div>
              </div>
              <div class="btn-icon" @click="addUser(row)">
                <img
                  v-if="!row.userInfoVOList || row.userInfoVOList.length == 0"
                  src="@/assets/uExam/icon_add.png"
                  alt=""
                />
                <img v-else src="@/assets/uExam/icon_edit.png" alt="" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="{ row }">
            <template>
              <el-button type="text" @click="showEdit(row)"> 修改 </el-button>
              <el-button
                type="text"
                style="color: #ff0000"
                @click="delStudent(row)"
              >
                删除
              </el-button>
            </template>
            <!-- <template v-else>-</template> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <importFile ref="importFile"></importFile>
    <addAdmin ref="addAdmin"></addAdmin>
    <addScanning ref="addScanning"></addScanning>
    <delVerify ref="delVerify"></delVerify>
    <exportFile ref="exportFile"></exportFile>
  </div>
</template>

<script>
import { getObj } from "@/core/api/exam/exam";
import { unionsubschool } from "@/core/api/exam/examTeacher";
import { examscanpointList, deletePoint } from "@/core/api/exam/examScanning";
import detailsHeader from "@/components/detailsHeader.vue";
import importFile from "./components/importFile.vue";
import addAdmin from "./components/addAdmin.vue";
import addScanning from "./components/addScanning.vue";
import delVerify from "./components/delVerify.vue";
import exportFile from "./components/exportFile.vue";
export default {
  name: "ExamScanning",
  components: {
    detailsHeader,
    importFile,
    addAdmin,
    addScanning,
    delVerify,
    exportFile,
  },
  data() {
    return {
      examInformation: {},
      keyword: "",
      status: "",
      dataList: [],
      schoolList: [],
      multipleSelection: [],
      listLoading: false,
    };
  },
  created() {
    this.getObj();
    this.getExamSchools();
    this.getExamScanning();
  },
  methods: {
    showClear() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择需要批量删除扫描点",
          type: "warning",
        });
        return;
      }
      this.$refs.delVerify.init(this.multipleSelection, 1);
    },
    delStudent(row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [
          h("span", null, "此操作将删除 "),
          h("span", { style: "color: #F57574" }, row.name),
          h("span", null, " 信息 ，是否继续? "),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            let data = {
              ids: row.id,
            };
            this.deleteItem(instance, done, data);
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    deleteItem(instance, done, data) {
      deletePoint(data)
        .then(() => {
          done();
          instance.confirmButtonLoading = false;
          this.getExamScanning();
          this.$message({
            showClose: true,
            message: "删除成功！",
            type: "success",
          });
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    showAddStudent() {
      this.$refs.addScanning.init();
    },
    showEdit(row) {
      this.$refs.addScanning.init(row);
    },
    async getExamSchools() {
      const res = await unionsubschool({
        examId: this.$route.query.examId,
        needUnit: true,
      });
      this.schoolList = res.data.data;
    },
    getExamScanning() {
      this.listLoading = true;

      examscanpointList({
        examId: this.$route.query.examId,
        keyword: this.keyword,
      })
        .then((res) => {
          this.dataList = res.data.data.map((item) => {
            if (item.schoolNames) {
              item.schoolNames = item.schoolNames.split(",");
            }

            return item;
          });
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    importFile() {
      this.$refs.importFile.init();
    },
    exportList() {
      this.$refs.exportFile.init();
    },
    addUser(row) {
      this.$refs.addAdmin.init(row);
    },
    handleSelectionChange(e) {
      this.multipleSelection = e;
    },
    handleClick() {
      this.getExamScanning();
    },
    showExport() {},
    //获取考试基本信息
    getObj() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        //获取当前年级班级
        this.status = this.examInformation.status;
      });
    },
  },
};
</script>

<style lang="scss">
// .popover-tips {
//   min-width: auto;
//   padding: 18px;
//   color: #0a1119;
//   .btn-item {
//     cursor: pointer;
//   }
// }
</style>
<style scoped lang="scss">
.ExamScanning {
  padding-top: 18px;
  .user-box {
    display: flex;
    // flex-wrap: wrap;
    align-items: flex-start;
    // justify-content:;
    .user-list {
      display: flex;
      flex-wrap: wrap;

      .user-list-box {
        display: flex;
        border-radius: 4px;
        border: 1px solid #f2f6ff;
        margin: 4px 6px;
        .name {
          background-color: #f5f7fa;
        }
        > div {
          padding: 4.5px 8px;
        }
      }
    }
  }
  ::v-deep {
    .tab-box .el-table .el-table__cell {
      padding: 2px 0;
    }
  }

  .name-box {
    display: flex;
    flex-wrap: wrap;
    div {
      padding: 6px 8px;
      background-color: #f5f7fa;
      margin: 4px 6px;
      border-radius: 4px;
      flex-shrink: 0;
    }
  }
  ::v-deep
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #ffffff;
  }
  .btn-icon {
    width: 36px;
    height: 32px;
    background-color: #f2f6ff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    margin: 4px 0;
    img {
      width: 16px;
    }
  }
  .tab-box {
    padding: 18px;
    background-color: #ffffff;
    margin-top: 18px;
    .btn-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;
    }
    .btn-list-box {
      display: flex;
      align-items: center;
      .el-input {
        width: 180px;
        margin-right: 10px;
      }
    }
  }
}
</style>
