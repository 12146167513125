<template>
  <div class="exportFile">
    <el-dialog
      title="导出"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="348px"
    >
      <div class="check-box">
        <span style="flex-shrink: 0"> 请选择要导出内容： </span>
        <!-- 导出类型 0-扫描点信息 1-管理员账号 -->
        <el-select v-model="type" placeholder="请选择">
          <el-option label="扫描点信息" :value="0"> </el-option>
          <el-option label="管理员账号" :value="1"> </el-option>
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" :loading="btnLoading" @click="monitorBack()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pointExport } from "@/core/api/exam/examScanning";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "ExportFile",
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      type: 0,
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
    },

    monitorBack() {
      let data = {
        examId: this.$route.query.examId,
        type: this.type,
      };
      this.btnLoading = true;
      pointExport(data)
        .then((res) => {
          this.btnLoading = false;
          this.dialogVisible = false;
          let { url, name } = res.data.data;
          fileDownloadByUrl(url, name);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.exportFile {
  .check-box {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
}
</style>
