<template>
  <div class="importFile">
    <el-dialog
      title="导入"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div class="tips">
            <div class="tips-title">【提示】</div>
            1、扫描点名称只能含有汉字，字母，数字或下划线；名称不能超过20个字；<br />
            2、同一学段下，扫描点名称不允许重复；<br />
            3、学校名必须与导入系统中的名称一致；<br />
            4、管理员必须与用户管理中的名称一致；<br />
            5、同一学段下，各扫描点学校名称不允许重复；<br />
            6、同一学段下，管理员扫描点名称不允许重复；<br />
            7、导入文件中，只要存在一条数据不合法，则所有数据都无法导入。<br />
          </div>
        </template>
        <template slot="down">
          <div class="down-box">
            <div>
              <img src="@/assets/uExam/icon-down.png" alt="" />
              下载模版：
              <span class="down-tips" @click="downFile()">
                扫描点导入模板.xlsx
              </span>
            </div>
          </div>
        </template>
      </ElImportUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >导 入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImportUpload from "@/components/ElImportUpload";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "ImportFile",
  components: { ElImportUpload },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      btnType: 0,
    };
  },
  created() {},
  methods: {
    submit() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    downFile() {
      let url =
        "https://static.wtjy.com/template/%E8%81%94%E8%80%83-%E8%80%83%E8%AF%95%E6%89%AB%E6%8F%8F%E7%82%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20.xlsx";
      let name = "扫描点导入模板.xlsx";
      fileDownloadByUrl(url, name);
    },
    upLoadSucc(data) {
      this.data = data;
      setTimeout(() => {
        this.$parent.getExamScanning();
      }, 2000);
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        let url = "/exam/examscanpoint/import";
        let accept = ".xlsx,.xls";
        this.$refs.ElImportUpload.init(url, accept);
        this.$refs.ElImportUpload.queryData = {
          examId: this.$route.query.examId,
        };
      });
    },
  },
};
</script>
<style scoped lang="scss">
.importFile {
}
</style>
