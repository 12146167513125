<template>
  <div class="addScanning">
    <el-dialog
      :title="ruleForm.id ? '修改扫描点' : '添加扫描点'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="668px"
    >
      <div class="tips">
        1、扫描点名称中只能含有汉字，字母，数字或者下划线；<br />
        2、扫描点名称不能超过20个字；
      </div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="90px"
      >
        <!-- <el-form-item label="适用学段：" prop="level">
          <el-select v-model="ruleForm.level" placeholder="请选择">
            <el-option
              v-for="(item, index) in levelOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item
          label="扫描点名称："
          prop="name"
          class="name-box is-required"
        >
          <el-input v-model="ruleForm.name" maxlength="20"></el-input>
        </el-form-item>
      </el-form>
      <p class="title-tips">
        <span style="color: #f57574; margin-right: 4px">*</span>选择扫描点学校：
      </p>
      <div class="check-box">
        <div class="check-list-box">
          <div class="title">
            <span class="span-btn" @click="checkChange(1)">全选</span>
            <span class="span-btn" @click="checkChange(2)">反选</span>
          </div>
          <div class="check-cnt edit-scroll-style">
            <el-input
              v-model="keyword"
              placeholder="可输入学校名称搜索"
              @keyup.enter.native="handleClick"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="handleClick"
              >
              </i>
            </el-input>
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(v, i) in schoolList"
                :key="i"
                :label="v.schoolId"
                @change="setCheckList()"
              >
                {{ v.schoolName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="check-list-box check-list-box-r">
          <div class="title">
            <p>
              已选 <span>{{ checkAllList.length }}</span> 所学校
            </p>
            <el-button
              type="text"
              icon="el-icon-refresh-left"
              @click="clearItem()"
              >清空</el-button
            >
          </div>
          <div class="check-cnt edit-scroll-style">
            <el-input
              v-model="keywordR"
              placeholder="可输入学校名称搜索"
              @keyup.enter.native="setShowList"
            >
              <i
                slot="suffix"
                class="el-icon-search el-input__icon"
                @click="setShowList"
              >
              </i>
            </el-input>
            <div class="list-box">
              <div v-for="(v, i) in showList" :key="i" class="check-list">
                {{ v.schoolName }}
                <i class="el-icon-close" @click="delItem(v)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { levelOptions } from "@/core/util/constdata";
import { saveorupdate, otherschool } from "@/core/api/exam/examScanning";
//
export default {
  name: "AddScanning",
  components: {},
  data() {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入扫描点名称"));
      }
      setTimeout(() => {
        const lint = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
        if (!lint.test(value)) {
          callback(new Error("扫描点名称只能含有汉字，字母，数字或下划线"));
        } else {
          callback();
        }
      }, 500);
    };
    return {
      dialogVisible: false,
      btnLoading: false,
      keyword: "",
      keywordR: "",
      ruleForm: {
        name: "",
        id: "",
      },
      levelOptions: levelOptions(),
      checkList: [],
      schoolList: [],
      showList: [],
      checkAllList: [],
      rules: {
        name: [{ validator: validateName, trigger: "blur" }],
        level: [
          { required: true, message: "请选择适用学段", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  methods: {
    submit() {
      this.btnLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.editSaveorupdate();
        } else {
          this.btnLoading = false;
        }
      });
    },
    editSaveorupdate() {
      if (this.checkList.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择学校",
          type: "warning",
        });
        return;
      }
      let data = {
        schoolIds: this.checkList.join(","),
        examId: this.$route.query.examId,
      };
      Object.assign(this.ruleForm, data);

      this.btnLoading = true;
      saveorupdate(this.ruleForm)
        .then(() => {
          this.$message({
            showClose: true,
            message: this.ruleForm.id ? "修改成功！" : "添加成功！",
            type: "success",
          });
          this.dialogVisible = false;

          setTimeout(() => {
            this.btnLoading = false;
          }, 500);
          this.$parent.getExamScanning();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    delItem(row) {
      this.checkList = this.checkList.filter((item) => item != row.schoolId);
      this.setCheckList();
    },
    clearItem() {
      this.checkList = [];
      this.setCheckList();
    },
    checkChange(type) {
      if (type == 1) {
        let arr = this.schoolList.map((item) => item.schoolId);
        this.checkList = this.checkList.concat(arr);
        this.checkList = [...new Set(this.checkList)];
      }
      if (type == 2) {
        let arr = this.schoolList.map((item) => item.schoolId);
        // 过滤出原本应该有的 但是又不在当前显示列表的
        let array = this.checkList.filter((item) => !arr.includes(item));
        arr = arr.filter((item) => !this.checkList.includes(item));
        this.checkList = array.concat(arr);
      }
      this.setCheckList();
    },
    // 根据本地数据设置所选择的内容
    setCheckList() {
      this.checkAllList = this.oldScohhoList.filter((item) =>
        this.checkList.includes(item.schoolId)
      );

      this.setShowList();
    },
    // 过滤出根据搜索条件拿到的数据
    setShowList() {
      this.showList = this.checkAllList.filter((item) => {
        if (
          !this.keywordR ||
          (this.keywordR && item.schoolName.indexOf(this.keywordR) != -1)
        ) {
          return item;
        }
      });
    },
    async init(row) {
      this.checkAllList = [];
      this.checkList = [];
      this.oldSet = [];
      this.showList = [];
      await this.getList();
      // 添加判断  判断下是否还有学校没有添加到扫描点
      if (this.oldScohhoList.length == 0 && !row) {
        this.$message({
          showClose: true,
          message: "所有学校已添加完毕！",
          type: "warning",
        });
        return;
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.ruleForm = this.$options.data().ruleForm;
        if (row) {
          // console.log(row);
          // 编辑的情况 处理下数据
          let schoolIds = row.schoolIds.split(",").map((item) => Number(item));
          let schoolList = this.$parent.schoolList;
          this.checkAllList = schoolList.filter((item) =>
            schoolIds.includes(item.schoolId)
          );
          this.oldSet = JSON.parse(JSON.stringify(this.checkAllList));
          this.checkList = schoolIds;
          this.ruleForm.name = row.name;
          this.ruleForm.id = row.id;
          this.oldScohhoList = this.oldScohhoList.concat(this.oldSet);
          this.schoolList = JSON.parse(JSON.stringify(this.oldScohhoList));
          this.setShowList();
        }
      });
    },
    async getList() {
      await otherschool({
        examId: this.$route.query.examId,
      })
        .then((res) => {
          this.oldScohhoList = res.data.data.map((item) => {
            return {
              schoolId: item.id,
              schoolName: item.name,
            };
          });
          this.schoolList = JSON.parse(JSON.stringify(this.oldScohhoList));
        })
        .catch(() => {
          this.schoolList = [];
          this.oldScohhoList = [];
        });
    },
    handleClick() {
      this.schoolList = this.oldScohhoList.filter((item) => {
        if (
          !this.keyword ||
          (this.keyword && item.schoolName.indexOf(this.keyword) != -1)
        ) {
          return item;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.addScanning {
  .tips {
    background: #f3f8ff;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 18px;
  }
  .check-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .check-list-box-r {
      .title {
        display: flex;
        justify-content: space-between;
        p {
          margin-bottom: 0;
          span {
            color: #2474ed;
          }
        }
        .el-button {
          color: rgb(245, 117, 116);
        }
      }
    }
    .check-list-box {
      width: 298px;
      border: 1px solid #dee0e7;
      .check-cnt {
        padding: 18px;
        height: 372px;
        .list-box {
          .check-list {
            display: flex;
            justify-content: space-between;
            margin-top: 18px;
            i {
              cursor: pointer;
            }
          }
        }
        .el-checkbox {
          display: block;
          margin-top: 18px;
          margin-bottom: 0;
        }
      }
      .title {
        padding: 10px 18px;
        background: #f5f7fa;
        border-bottom: 1px solid #dee0e7;
        .span-btn {
          margin-right: 18px;
          cursor: pointer;
        }
      }
    }
  }
  .title-tips {
    margin-bottom: 18px;
  }
  .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 18px;
    }
    ::v-deep .el-form-item__label {
      padding: 0;
      text-align: left;
    }
    ::v-deep label {
      margin-bottom: 0;
    }
    .el-select {
      width: 120px;
    }
    .name-box {
      // margin-left: 24px;/

      ::v-deep .el-form-item__label {
        width: 104px !important;
      }
      ::v-deep .el-form-item__content {
        margin-left: 104px !important;
        width: 280px;
      }
    }
  }
}
</style>
