<template>
  <div class="addAdmin">
    <el-dialog
      title="添加管理员"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="1273px"
    >
      <div class="content-box">
        <div>
          <div class="filter-box">
            <div class="filter-item">
              学校：
              <el-select
                v-model="search.schoolId"
                placeholder="请选择学校"
                filterable
                @change="handleClick"
              >
                <el-option label="全部学校" value=""> </el-option>
                <el-option
                  v-for="item in schoolList"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              分类：

              <el-select
                v-model="search.type"
                placeholder="请选择分类"
                @change="handleClick"
              >
                <el-option label="全部" value=""> </el-option>
                <el-option
                  v-for="item in typeOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              <el-input
                v-model="search.keyword"
                placeholder="请输入姓名或用户名"
                @keyup.enter.native="handleClick"
              >
                <i
                  slot="suffix"
                  class="el-icon-search el-input__icon"
                  @click="handleClick"
                >
                </i>
              </el-input>
            </div>
          </div>
          <p class="user-tips">
            系统用户 <span>{{ teacherList.length }}</span> 人
          </p>
          <el-table
            ref="multipleTable"
            :data="teacherList"
            tooltip-effect="dark"
            style="width: 100%"
            border
          >
            <el-table-column width="55">
              <template #header>
                <el-checkbox
                  v-model="checked"
                  :indeterminate="false"
                  @change="setList()"
                ></el-checkbox>
              </template>
              <template slot-scope="{ row }">
                <el-checkbox-group
                  v-model="checkList"
                  @change="setSingleList()"
                >
                  <el-checkbox :label="row.id">{{ "" }}</el-checkbox>
                </el-checkbox-group>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              width="140"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="username"
              label="用户名"
              width="140"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolName"
              label="学校"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
        </div>
        <div>
          <p class="user-tips">
            已选用户 <span>{{ checkTeacher.length }}</span> 人
            <el-button
              class="clear-btn"
              type="text"
              icon="el-icon-refresh-left"
              @click="clearAll()"
            >
              清空
            </el-button>
          </p>
          <div class="filter-box">
            <div class="filter-item">
              学校：
              <el-select
                v-model="searchR.schoolId"
                placeholder="请选择学段"
                @change="filterTeacher"
              >
                <el-option label="全部学校" value=""> </el-option>
                <el-option
                  v-for="item in schoolList"
                  :key="item.schoolId"
                  :label="item.schoolName"
                  :value="item.schoolId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="filter-item">
              <el-input
                v-model="searchR.keyword"
                placeholder="请输入姓名或用户名"
                @keyup.enter.native="filterTeacher"
              >
                <i
                  slot="suffix"
                  class="el-icon-search el-input__icon"
                  @click="filterTeacher"
                >
                </i>
              </el-input>
            </div>
          </div>
          <el-table
            ref="multipleTable"
            :data="checkTeacher"
            tooltip-effect="dark"
            style="width: 100%"
            border
          >
            <el-table-column
              prop="name"
              label="姓名"
              width="140"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="username"
              label="用户名"
              width="140"
              show-overflow-tooltip
              align="center"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="schoolName"
              label="学校"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column align="center" width="40">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  icon="el-icon-close"
                  @click="delItem(row)"
                >
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { otheruser, saveorupdateUser } from "@/core/api/exam/examScanning";
import { schoolunionuserType } from "@/core/api/cms/geo.js";
export default {
  name: "AddAdmin",
  components: {},
  data() {
    return {
      checked: false,
      btnLoading: false,
      dialogVisible: false,

      schoolList: [],
      typeOptions: [],
      //   左侧选中列表
      checkList: [],
      //   左侧列表
      teacherList: [],
      //   右侧显示的列表
      checkTeacher: [],
      //   右侧所有的列表
      checkAllTeacher: [],
      search: {
        keyword: "",
        schoolId: "",
        type: "",
        //         examId: 28671
        // schoolId: 20265
        // schoolIdSub: 20221
      },
      searchR: {
        keyword: "",
        schoolId: "",
        //         examId: 28671
        // schoolId: 20265
        // schoolIdSub: 20221
      },
    };
  },

  created() {},
  methods: {
    submit() {
      let ids = this.checkAllTeacher.map((item) => item.id);

      let data = {
        id: this.indexObj.id,
        unionUserIds: ids.join(","),
      };
      this.btnLoading = true;
      saveorupdateUser(data)
        .then(() => {
          this.dialogVisible = false;
          this.btnLoading = false;
          this.$parent.getExamScanning();
          this.$message({
            showClose: true,
            message: data.id ? "修改成功" : "添加成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    setList() {
      let arr = this.teacherList.map((item) => item.id);
      if (this.checked) {
        this.checkList = this.checkList.concat(arr);
      } else {
        this.checkList = this.checkList.filter(
          (item) => arr.indexOf(item) == -1
        );
      }
      this.checkList = [...new Set(this.checkList)];
      this.getTeacherList();
    },
    setSingleList() {
      this.getTeacherList();
    },
    // 依次获取出左侧需要被删除的数据  被添加的数据
    getTeacherList() {
      let arr = this.teacherList.map((item) => {
        return item;
      });
      let oldArr = this.checkAllTeacher.map((item) => item.id);
      let addList = arr.filter(
        (item) => this.checkList.includes(item.id) && !oldArr.includes(item.id)
      );
      let delList = oldArr.filter((item) => !this.checkList.includes(item));
      this.checkAllTeacher = this.checkAllTeacher.concat(addList);
      this.checkAllTeacher = this.checkAllTeacher.filter(
        (item) => !delList.includes(item.id)
      );
      this.filterTeacher();
    },
    delItem(row) {
      this.checkList = this.checkList.filter((item) => item != row.id);
      this.getTeacherList();
    },
    filterTeacher() {
      let { schoolId, keyword } = this.searchR;

      this.checkTeacher = this.checkAllTeacher.filter((item) => {
        if (
          ((schoolId && item.schoolId == schoolId) || !schoolId) &&
          (!keyword ||
            (keyword && item.name.indexOf(keyword) != -1) ||
            item.username.indexOf(keyword) != -1)
        ) {
          return item;
        }
      });
    },
    clearAll() {
      this.checkAllTeacher = [];
      this.checkTeacher = [];
      this.checkList = [];
    },
    //获取老师信息
    normalFetchList() {
      let data = {
        examId: this.$route.query.examId,
      };
      Object.assign(data, this.search);
      otheruser(data)
        .then((response) => {
          this.teacherList = response.data.data.concat(this.userInfoVOList);
        })
        .catch(() => {});
    },
    async getSchoolteachertype() {
      const res = await schoolunionuserType({
        examId: this.$route.query.examId,
      });
      this.typeOptions = res.data.data;
    },
    handleClick() {
      this.checked = false;
      this.normalFetchList();
    },
    init(row) {
      this.indexObj = row;
      this.dialogVisible = true;
      this.schoolList = this.$parent.schoolList;
      // 初始化 清空
      this.checkAllTeacher = [];
      this.checkTeacher = [];
      this.checkList = [];
      this.userInfoVOList = [];
      if (row.userInfoVOList) {
        let userInfoVOList = row.userInfoVOList.map((item) => {
          item.id = item.unionUserId;
          return item;
        });
        this.userInfoVOList = userInfoVOList;
        this.checkList = userInfoVOList.map((item) => item.id);
        this.checkAllTeacher = JSON.parse(JSON.stringify(userInfoVOList));
        this.filterTeacher();
      }
      this.normalFetchList();
      this.getSchoolteachertype();
    },
  },
};
</script>
<style scoped lang="scss">
.addAdmin {
  .clear-btn {
    color: #f57574;
    ::v-deep span {
      margin-left: 0;
    }
  }

  .content-box {
    display: flex;
    border: 1px solid #dee0e7;
    ::v-deep {
      .el-table td.el-table__cell,
      .el-table th.el-table__cell.is-leaf {
        border: none;
      }

      .el-table__body-wrapper {
        height: 449px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
        }

        ///*<!--修改 滑块 -->*/
        // border: 1px solid #DEE1E7;
        &::-webkit-scrollbar-thumb {
          background-color: #dee1e7;
        }
      }
    }
    .user-tips {
      margin-bottom: 18px;
      span {
        color: #2474ed;
      }
    }
    .filter-box {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 18px;
      margin-bottom: 18px;
      .filter-item {
        display: flex;
        white-space: nowrap;
        align-items: center;
      }
    }
    > div {
      width: 50%;
      padding: 18px;
      &:first-child {
        border-right: 1px solid #dee0e7;
      }
    }
  }
}
</style>
