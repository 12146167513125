<template>
  <div class="delVerify">
    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="400px">
      <div>
        此操作将删除
        <span style="color: #f57574">{{ dataList.length }}条 </span>
        扫描点信息，将不可恢复，请身份验证后继续。
      </div>
      <div class="password">
        <span>请输入登录密码：</span>

        <el-input
          v-model="name"
          style="width: 1px; height: 1px; opacity: 0"
          placeholder="请输入内容"
        ></el-input>

        <a-input-password
          v-model="password"
          placeholder=""
          autocomplete="new-password"
          @keyup.enter.native="monitorBack"
        />
      </div>
      <div style="margin-top: 12px; text-align: center">此操作不可撤销</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="danger" :loading="btnLoading" @click="monitorBack()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deletePoint } from "@/core/api/exam/examScanning";
import { encryption } from "@/core/util/util";
export default {
  name: "DelVerify",
  components: {},
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      password: "",
      schoolName: "",
      showType: "",
      name: "",
      dataList: [],
    };
  },
  created() {},
  methods: {
    monitorBack() {
      if (!this.password) {
        this.$message({
          showClose: true,
          message: "请输入密码！",
          type: "warning",
        });
        return;
      }

      let userInfo = {
        randomStr: "blockPuzzle",
        code: "xxx",
        password: this.password,
      };
      const user = encryption({
        data: userInfo,
        key: "pigxpigxpigxpigx",
        param: ["password"],
      });
      let data = {
        examId: this.$route.query.examId,
        password: user.password,
      };
      data.ids = this.dataList.map((item) => item.id).join(",");

      this.deleteItem(data);
    },

    deleteItem(data) {
      this.btnLoading = true;

      deletePoint(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "删除成功",
            type: "success",
          });
          this.$parent.getExamScanning();
          this.dialogVisible = false;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init(row) {
      this.name = "";
      this.password = "";
      this.dialogVisible = true;
      this.dataList = row;
    },
  },
};
</script>
<style scoped lang="scss">
.password {
  margin-top: 12px;
  display: flex;
  align-items: center;
  .ant-input-affix-wrapper {
    width: auto;
  }
  span {
    flex-shrink: 0;
  }
}
.delVerify {
}
</style>
